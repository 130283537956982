.comment-popover-overlay {
  width: 332px;
  max-width: 100%;
}

.comment-popover-overlay .ant-popover-arrow {
  display: none;
}

.comment-popover-overlay .ant-popover-title {
  border-bottom: none;
  padding: 12px;
}

.comment-popover-overlay .ant-popover-inner-content {
  padding: 0;
}

.ant-popover {
  z-index: 999;
}

.ant-popover .ant-popover-inner {
  padding: 0;
}

.ant-popover-inner-content {
  padding: 12px 16px;
}