.ant-select .ant-select-arrow {
  width: 24px;
  height: 24px;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  margin-top: 0;
}

.ant-select-dropdown {
  padding: 4px 0;
}

.ant-select-dropdown .ant-select-item {
  border-radius: 0;
}

.ant-select-dropdown .ant-select-item-option-content {
  white-space: pre-wrap;
}

.ant-select-multiple .ant-select-selection-item {
  border-radius: 8px;
  border: 1px solid transparent;
}

.ant-select-dropdown svg {
  width: 100%;
  max-width: 100%;
}

.ant-select-dropdown .ant-select-item-option {
  align-items: center;
}

.ant-select-dropdown .ant-select-item-option-state {
  width: 24px;
  height: 24px;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--sheeta--primary-400);
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--sheeta--danger-500);
}
