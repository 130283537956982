@import './table.less';
@import './multiSelect.less';
@import './popover.less';
@import './moreButton.less';
@import './panelMenu.less';
@import './button.less';
@import './datePicker.less';
@import './message.less';
@import './select.less';
@import './tag.less';

.ant-layout {
  background-color: var(--conditional-page-background);
}
@primary-100: #d1e9ff;@primary-200: #b2ddff;@primary-300: #84caff;@primary-400: #53b1fd;@primary-500: #2e90fa;@primary-600: #1570ef;@primary-700: #175cd3;@primary-800: #1849a9;@primary-900: #194185;@basic-100: #ffffff;@basic-200: #f7f9fc;@basic-300: #edf1f7;@basic-400: #e4e9f2;@basic-500: #e2e6ee;@basic-600: #7e8299;@basic-700: #2e3a59;@basic-800: #222b45;@basic-900: #192038;@basic-1000: #151a30;@basic-1100: #181c32;@success-100: #d4ffee;@success-200: #b3ffd6;@success-300: #8cfac7;@success-400: #27e2a2;@success-500: #00d59c;@success-600: #00b383;@success-700: #008f72;@success-800: #007566;@success-900: #00524c;@warning-100: #fff4dd;@warning-200: #fee4b3;@warning-300: #fed08e;@warning-400: #ffcb7e;@warning-500: #ffa800;@warning-600: #da7b30;@warning-700: #b86e00;@warning-800: #945400;@warning-900: #873800;@danger-100: #fee2e4;@danger-200: #fcccbc;@danger-300: #f8a999;@danger-400: #fd97a1;@danger-500: #f64e5f;@danger-600: #db3442;@danger-700: #b7243c;@danger-800: #871a37;@danger-900: #701032;@conditional-pop-over: #ffffff;@conditional-item-background-hover: #f7f9fc;@conditional-divider: #edf1f7;@conditional-page-background: #f9fafb;