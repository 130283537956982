.table-tooltip {
  max-width: 204px;
}
.ant-tabs-ink-bar {
  background: var(--sheeta--primary-500)
}
.ant-table-content {
  &::-webkit-scrollbar {
    height: 7px;
    border-radius: 27px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 27px;
    background-color: var(--sheeta--scrollbar-thumb);
  }
}
.ant-pagination {
  padding: 20px 24px;
  margin: 0 0 40px 0 !important;
  background-color: var(--sheeta--basic-100);
  border-radius: 0 0 8px 8px;
}