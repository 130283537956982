.moreButton__dropDown {
  height: 186px;

  .moreButton__name {
    color: var(--sheeta--basic-600);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 4px 15px 0;
  }

  .ant-dropdown-menu {
    text-align: center;

    .ant-dropdown-menu-item {
      margin: 8px;
      font-weight: 500;

      &:hover {
        background-color: var(--sheeta--primary-100);
        border-radius: 8px;
      }

      &.moreButton__delete {
        color: var(--sheeta--danger-500);

        &:hover {
          background-color: var(--sheeta--danger-100);
          border-radius: 8px;
        }
      }
    }
  }
}