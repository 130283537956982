.datePicker__calendar-panel .rangePicker__shortcuts span {
  margin: 0 4px;
  padding: 2px 10px;
  border-radius: 34px;
  border: solid 1px var(--sheeta--primary-500);
  background-color: var(--sheeta--primary-100);
  color: var(--sheeta--primary-500);
  font-weight: 500;
}

.datePicker__calendar-panel .rangePicker__shortcuts span:first-of-type {
  margin-left: 0;
}
