@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    margin: 0;
    font-family: Montserrat, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scrollbar-width: none;
    color: var(--sheeta--basic-800);
    background-color: var(--conditional-page-background) !important;
    line-height: 1.5;
}

body::-webkit-scrollbar {
    display: none;
}

.ant-scrolling-effect {
    width: 100% !important;
}

* {
    box-sizing: border-box;
}

*,
*:focus,
*:hover {
    outline: none;
}

a {
    list-style: none;
    color: var(--basic-800);
}

h1 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 0;
}

#root {
    height: 100%;
}

h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
}

h3 {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 0;
}

h4 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 0;
}

h5 {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 0;
}

h6 {
    font-size: 18px;
    font-weight: bold;
}

img {
    max-width: 100%;
    max-height: 100%;
}

:root {
    --sheeta--avatar-bg-color-1: #4e6aff;
    --sheeta--background-1: #f0f3f7;
    --sheeta-disabled: #e0e0e0;
    --sheeta--scrollbar-thumb: #d8d8d8;
    --sheeta--dark: #000000;
    --sheeta--border-bottom: rgba(0, 0, 0, 0.06);
    --sheeta-home-linear-bg-1: linear-gradient(99deg, #4377dc 3%, #5da8df 100%);

    --sheeta--primary-100: #d1e9ff;
    --sheeta--primary-200: #b2ddff;
    --sheeta--primary-300: #84caff;
    --sheeta--primary-400: #53b1fd;
    --sheeta--primary-500: #2e90fa;
    --sheeta--primary-600: #1570ef;
    --sheeta--primary-700: #175cd3;
    --sheeta--primary-800: #1849a9;
    --sheeta--basic-100: #ffffff;
    --sheeta--basic-200: #f7f9fc;
    --sheeta--basic-300: #edf1f7;
    --sheeta--basic-400: #e4e9f2;
    --sheeta--basic-500: #e2e6ee;
    --sheeta--basic-600: #7e8299;
    --sheeta--basic-700: #2e3a59;
    --sheeta--basic-800: #222b45;
    --sheeta--basic-900: #192038;
    --sheeta--basic-1000: #151a30;
    --sheeta--basic-1100: #181c32;
    --sheeta--danger-100: #fee2e4;
    --sheeta--danger-200: #fcccbc;
    --sheeta--danger-300: #f8a999;
    --sheeta--danger-400: #fd97a1;
    --sheeta--danger-500: #f64e5f;
    --sheeta--danger-600: #db3442;
    --sheeta--danger-700: #b7243c;
    --success-100: #d4ffee;
    --success-500: #00d59c;
    --success-600: #00b383;
    --warning-100: #fff4dd;
    --warning-200: #fee4b3;
    --warning-300: #fed08e;
    --warning-400: #ffcb7e;
    --warning-500: #ffa800;
    --warning-600: #da7b30;
    --warning-700: #b86e00;

    --image-overlay-1-dark: rgba(0, 0, 0, 0.45);
    --image-overlay-1-fill-4-super-dark: rgba(0, 0, 0, 0.75);
    --image-overlay-1-super-dark: rgba(0, 0, 0, 0.85);
    --conditional-page-background: #f9fafb;

    --colorBorder: #e2e6ee;
    --colorInfo: #3266FF;
    --colorPrimary: #3266FF;
    --colorLink: #3266FF;
    --colorTextPlaceholder: #7e8299;
    --controlItemBgActive: #e4eaff;
}
