.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-item-selected::after {
  background: transparent;
  border: none !important;
}

.ant-menu-item-selected {
  a {
    color: var(--sheeta--primary-700);
  }
  span {
    svg {
      fill: var(--sheeta--primary-700);
      g {
        fill: var(--sheeta--primary-700);
      }
      path {
        fill: var(--sheeta--primary-700);
      }
    }
  }
}

.ant-menu-item:not(.ant-menu-item-selected):hover {
  a {
    color: var(--sheeta--primary-700);
  }
  svg {
    fill: var(--sheeta--primary-700);
    g {
      fill: var(--sheeta--primary-700);
    }
    path {
      fill: var(--sheeta--primary-700);
    }
  }
}